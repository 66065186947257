// material-ui
import { Box, FormControl, OutlinedInput, InputLabel, MenuItem, ListItemText, Select, Checkbox } from '@mui/material';

// assets
import { useCommonHooks } from 'hooks/common';
import { useLang } from 'hooks/common/lang';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + (ITEM_HEIGHT / 2 + 4),
            width: 250
        }
    }
};

const Search = () => {
    const { $t } = useLang();
    const { shop, handleChangeSelectedShops } = useCommonHooks();

    const handleChange = (event, child) => {
        const {
            target: { value }
        } = event;

        if (child?.props?.value === '__ALL_SHOPS__') {
          const currentValue = value.filter(v => v !== '__ALL_SHOPS__');

          if (currentValue.length === shop.listName.length) {
              handleChangeSelectedShops([shop.listName[0]]);
          } else {
              handleChangeSelectedShops(shop.listName.map(name => name))
          }

          return;
        }

        handleChangeSelectedShops(value);
    };

    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Outlet</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={shop.selectedName || []}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    <MenuItem value="__ALL_SHOPS__">
                        <Checkbox checked={shop.selectedName.length === shop.listName.length} />
                        <ListItemText primary={$t('general.OpAllOutlet')} />
                    </MenuItem>
                    {shop.listName.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={shop.selectedName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default Search;
